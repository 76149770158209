import React, { useState } from "react";
import styles from './index.module.scss';
// import stylesm from './index_mobile.module.scss';
// import Title from "shared/ui/Title/Title";
// import ProductsForInvoiceCard from "widgets/ProductsForInvoiceCard";
// import { ReceivedProductCellType } from "../../../Placement/model";
import clsx from "clsx";
// import { SearchInputMobile } from "shared/ui/SearchInputMobile";
// import { Sort as MobileSort } from "shared/ui-mobile/Sort";
import { MultipleSelect } from "shared/ui/MultipleSelect";
// import { SelectedOptions } from "shared/ui-mobile/SelectedOptions";
// import { ItemMobile } from "./ItemMobile";
import { SearchInput } from "shared/ui/SearchInput";
import { Sort } from "shared/ui/Sort";
// import { Options } from "shared/ui/Options";
// import { CellProducts } from "./CellProducts";
import { useGetOrganizationsByIdProductsQuery, useGetProductsReceivedQuery } from "api";
import { sortDataEl } from "functions/sort";
import { filterDataEl } from "functions/filter";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { Container } from "shared/ui/Container";
import boxImg from "shared/assets/images/box.png";
import { Button } from "shared/ui/Button";
import { OptionType } from "shared/ui/Select";
import { getProductQuantity } from "functions/product";
import { getImageUrl } from "functions/image";

type PropsType = {
    clientId: number;
    closeModal: () => void;
};
const options: OptionType[] = [
    {value: "Название", key: 1},
    {value: "Артикул", key: 2},
    {value: "Заводской ШТ", key: 3},
    {value: "Штрихкод", key: 4},
    {value: "Остаток на складе", key: 5},
];

const InventoryProductsModal = ({clientId, closeModal}: PropsType) => {
    const {data: productsByUser} = useGetOrganizationsByIdProductsQuery({id: clientId, notNullQuantity: true});
    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);
    const [searchText, setSearchText] = useState("");

    const filteredData = productsByUser?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el?.name, key: 1},
        {value: el?.article, key: 2},
        {value: el?.factoryBarcode, key: 3},
        {value: el?.barcode, key: 4},
        {value: getProductQuantity(el), key: 5},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(1);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2?.name, value1: el1?.name, key: 1},
        {value2: el2?.article,  value1: el1?.article, key: 2},
        {value2: el2?.factoryBarcode, value1: el1?.factoryBarcode, key: 3},
        {value2: el2?.barcode, value1: el1?.barcode, key: 4},
        {value2: getProductQuantity(el2), value1: getProductQuantity(el1), key: 5},
    ], selectedOption, isDesc));

    return (
        <>
            <div className={styles.container}>
                <TitleForm title="Товары клиента"/>
                <div className={styles.header}>
                    <SearchInput
                        classNames={styles.searchInput}
                        value={searchText}
                        onChange={setSearchText}
                        inlineStyles={{marginRight: 10, width: "52%"}}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptionKeys={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10, width: 135}}
                        />
                    <Sort 
                        options={options}
                        selectedOptionKey={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        selectClassNames={styles.sort}
                        />
                </div>
                <div>
                    {sortedData?.map(product => (
                        <Container inlineClasses={styles.product__container}>
                            <>
                                <img
                                    className={styles.img} 
                                    src={getImageUrl(product.imageLinks)} alt={product?.name ?? ""} 
                                    />
                                <div className={styles.textWrapper}>
                                    <div className={styles.text}>Наименование: {product?.name}</div>
                                    <div className={styles.text}>Артикул: {product?.article}</div>
                                    <div className={styles.text}>
                                        Заводской ШТ: {product?.barcode}
                                    </div>
                                    <div className={styles.text}>Внешний ШТ: {product?.factoryBarcode}</div>
                                    <div className={clsx(styles.quantity, styles.text)}>
                                        Остаток на складе: {getProductQuantity(product)}
                                    </div>
                                </div>
                            </>
                        </Container>
                    ))}
                    {sortedData?.length == 0 && <div style={{textAlign: "center", margin: "120px 0 180px 0"}}>Нету элементов</div>}
                </div>
            </div>
            <div>
                <Button text="Закрыть" onClick={closeModal} inlineStyles={{marginTop: 10}}/>
            </div>
{/* 
        <div className={clsx(styles.productModal, styles.pc)}>
            <div className={styles.wrapperTitle}>
                <Title>Товары</Title>
            </div>
            <div style={{width: "100%"}} className={clsx(styles.search_storage)}>
                <div style={{display: "flex", marginBottom: "10px"}}>
                    <SearchInput
                        value={searchText} 
                        onChange={setSearchText}
                        />
                    <MultipleSelect 
                        text="Параметры"
                        options={options} 
                        selectedOptions={selectedOptions} 
                        setSelectedOptions={setSelectedOptions}
                        inlineStyles={{marginRight: 10}}
                        />
                    <Sort 
                        options={options}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setIsDesc={setIsDesc}
                        isDesc={isDesc}
                        />
                </div>
                <Options 
                    selectedOptions={selectedOptions} 
                    setSelectedOptions={setSelectedOptions}
                    />
            </div>
            <div className={styles.wrapperCard}>
                <ProductsForInvoiceCard>
                    {sortedData.map(item => (
                        <CellProducts key={`${item.ownerId}-${item.barcode}`} item={item}/>
                    ))}
                </ProductsForInvoiceCard>
            </div>
        </div> */}
        </>
    )
};

export default InventoryProductsModal;