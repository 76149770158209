import React from "react";
import styles from "./index.module.scss";
import Layout from "widgets/Layout/Layout";
import {Container as SharedContainer} from "shared/ui/Container";
import { Button } from "shared/ui/Button";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetBillsQuery, usePostProcessesPlacementAddProcessMutation } from "api";
import { BillStatusEnum } from "pages/Inspection/model";
import { useAppSelector } from "app/store/hooks/redux";
import Title from "shared/ui/Title/Title";
import { NavigateButton } from "shared/ui/NavigateButton";
import { BillType } from "enums/billType";

const PlacementPage = () => {
    const navigate = useNavigate();

    const {employeeWarehouseId} = useAppSelector(state => state.userReducer);
    const {data: placementBills} = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Placement, warehouseId: employeeWarehouseId!}, {skip: employeeWarehouseId == null});

    const [createProcess] = usePostProcessesPlacementAddProcessMutation();
    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
            }).unwrap();

            navigate({pathname: LinkPagesEnum.PlacementProcess, search: "?processId=" + processId});
        }
        catch (error) {
        }
    };

    if (employeeWarehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите склад в профиле</Title>
            <NavigateButton
                to={LinkPagesEnum.UserProfile}
                text="Перейти в профиль"
                />
        </div>;
    }

    return (
        <>
            <Layout>
                    <div className={styles.container}>
                        <Button
                            text="На главную"
                            onClick={() => navigate(LinkPagesEnum.Home)}
                            />
                        <div className={styles.title}>
                            Выберите накладную
                        </div>
                        <div className={styles.billsContainer}>
                            {
                                placementBills?.map(pBill => 
                                    <SharedContainer inlineClasses={styles.listContainer} onClick={() => {createProcesshandler(pBill.id!)}}>
                                        Накладная с Id = {pBill.id}
                                    </SharedContainer>
                                )
                            }
                        </div>
                    </div>
            </Layout>
        </>
    )
};

export {PlacementPage};