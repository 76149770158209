import { ReactNode } from "react";
import styles from "./index.module.scss";
import { Loader } from "shared/ui/Loader";

export type ColumnHeaderType = {
    name: string;
    width: string;
}

type PropsType = {
    columns: ColumnHeaderType[];
    children: ReactNode;
    isFetching?: boolean;
}

const Table = ({children, columns, isFetching}: PropsType) => {
    return (
        <div className={styles.table}>
            <div className={styles.header}>
                {columns.map(column => <div className={styles.column} style={{width: column.width}}>{column.name}</div>)}
            </div>
            <div className={styles.body}>
                {isFetching && <Loader inlineContainerClasses={styles.loader}/>}
                {!isFetching && children}
            </div>
        </div>
    );
};

export {
    Table
};