import React, { useEffect, useState } from "react";
import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { Button } from "shared/ui/Button";
import ButtonNav from "shared/ui/ButtonNav/ButtonNav";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useNavigate } from "react-router-dom";
import { Input } from "shared/ui/Input";
import { OptionType, Select } from "shared/ui/Select";
import { TextArea } from "shared/ui/TextArea";
import clsx from "clsx";
import { CreateBillModel, DifProduct, ReceivedProductOutputModel23, useGetProductsReceivedQuery, useGetUsersUserOrganizationsQuery, useGetWarehouseByIdProductsQuery, useGetWarehouseOrganizationsByIdWarehousesQuery, useGetWarehouseOrganizationsQuery, usePostBillsBillMutation } from "api";
import { useAppSelector } from "app/store/hooks/redux";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import Modal from "shared/ui/Modal/Modal";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { SearchInput } from "shared/ui/SearchInput";
import img from "shared/assets/images/box.png";
import { NavigateButton } from "shared/ui/NavigateButton";
import { NotMore } from "functions/math";

type Props = {
};

const columns: ColumnHeaderType[] = [
    {name: "Изображение", width: "15%"},
    {name: "Штрих-код", width: "10%"},
    {name: "Наименование", width: "20%"},
    {name: "Цвет", width: "5%"},
    {name: "Объём", width: "10%"},
    {name: "Упакованное количество", width: "15%"},
    {name: "Остаток", width: "15%"},
    {name: "Количество", width: "15%"},
]

const transporters: OptionType[] = [
    {value: "Склад", key: 1},
    {value: "Самостоятельно", key: 2},
    {value: "Транспортная компания", key: 3},
];

const AddingShipmentBillPage = ({}: Props) => {
    const navigate = useNavigate();
    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<ReceivedProductOutputModel23[]>([]);
    // const [isVisibleDescriptions, setIsVisibleDescriptions] = useState<number[]>([]);
    const [quantities, setQuantities] = useState<{id: number, quantity: number}[]>([]);

    const [newBill, setNewBill] = useState<CreateBillModel>({
        realizeDate: (new Date()).toISOString(),
        carId: 1,
        type: 1,
        transporter: transporters[0].value,
    });
    
    const [addAcceptanceBillQuery] = usePostBillsBillMutation();
    const {data: organizations} = useGetUsersUserOrganizationsQuery();
    const {data: receivedProductsByUser} = useGetWarehouseByIdProductsQuery({id: newBill.warehouseId!, organizationId: newBill.organizationId!}, {skip: newBill.warehouseId == null && newBill.organizationId == null});

    useEffect(()=> {
        if (organizations != null && organizations.length == 1) 
        {
            setNewBill({...newBill, organizationId: organizations[0].id!});
        }
    }, [organizations]);

    const addAcceptanceBill = () => {
        addAcceptanceBillQuery({createBillModel: {...newBill, products: addedProducts.map(product => {
            const quantity = quantities.find(quantity => quantity.id == product.product?.id)?.quantity;
            return {...product.product, quantity: quantity};
        })}});
        navigate(LinkPagesEnum.ShipmentBills);
    };

    const [modalSearch, setModalSearch] = useState("");
    
    const {data: warehouseOrganizations} = useGetWarehouseOrganizationsQuery();

    const [selectedWarehouseOrganizationId, setSelectedWarehouseOrganizationId] = useState<number | null>();
    const {data: warehouses, isFetching: isWarehousesFetching} = useGetWarehouseOrganizationsByIdWarehousesQuery({id: selectedWarehouseOrganizationId!}, {skip: selectedWarehouseOrganizationId == null});

    return (
        <Layout>
                <>
                    <div>
                        <div className={styles.header}>
                            <NavigateButton inlineStyles={{marginRight: 10}} text="Назад" to={LinkPagesEnum.ShipmentBills}/>
                            <Button text="Создать" onClick={addAcceptanceBill}/>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Информация
                                </div>
                                <div className={styles.fieldsContainer}>
                                    <div className={styles.field}>
                                        <Select 
                                            placeholder="Складская организация"
                                            options={warehouseOrganizations?.map(el =>({value: el.name!, key: el.id!}))}
                                            onChange={val => setSelectedWarehouseOrganizationId(val.key)}
                                            selectedOptionKey={selectedWarehouseOrganizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            nullOptionsText={selectedWarehouseOrganizationId ? "Нет записей" : "Выберите складскую организацию"}
                                            isFetching={isWarehousesFetching}
                                            placeholder="Склад"
                                            options={warehouses?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, warehouseId: val.key})}
                                            selectedOptionKey={newBill.warehouseId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Select 
                                            placeholder="Наименование организации"
                                            options={organizations?.map(el => ({value: el.name!, key: el.id!}))}
                                            onChange={val => setNewBill({...newBill, organizationId: val.key!})}
                                            selectedOptionKey={newBill.organizationId}
                                            inlineStyles={{marginBottom: 10}}
                                            />
                                        <Input
                                            placeholder="Внешний номер накладной"
                                            value={newBill.externalBillId}
                                            onChange={e => setNewBill({...newBill, externalBillId: e.target.value})}
                                            />
                                    </div>
                                    <TextArea
                                        classNamesContainer={clsx(styles.field, styles.comment)}
                                        placeholder="Комментарий"
                                        onChange={e => setNewBill({...newBill, note: e.target.value})}
                                        value={newBill.note}
                                        />
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    Доставка
                                </div>
                                <div className={styles.fieldsContainer} style={{flexWrap: "wrap"}}>
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Отправитель"
                                        value={newBill.sender}
                                        onChange={e => setNewBill({...newBill, sender: e.target.value})}
                                        />
                                    <Select 
                                        classNames={styles.field}
                                        placeholder="Перевозчик"
                                        options={transporters}
                                        onChange={val => setNewBill({...newBill, transporter: val.value})}
                                        selectedOptionKey={transporters.find(x => x.value == newBill.transporter)?.key}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input 
                                        classNamesContainer={styles.field}
                                        placeholder="Ожидаемая дата доставки"
                                        type="date"
                                        value={newBill.realizeDate!.split('T')[0]}
                                        onChange={e => setNewBill({...newBill, realizeDate: (new Date(e.target.value).toISOString())})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество коробов"
                                        value={newBill.boxexCount?.toString()}
                                        type="number"
                                        onChange={e => setNewBill({...newBill, boxexCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <Input
                                        classNamesContainer={styles.field}
                                        placeholder="Количество паллет"
                                        type="number"
                                        value={newBill.palletsCount?.toString()}
                                        onChange={e => setNewBill({...newBill, palletsCount: Number(e.target.value)})}
                                        inlineStyles={{marginBottom: 10}}
                                        />
                                    <TextArea
                                        classNamesContainer={styles.field}
                                        inlineStylesContainer={{height: 100}}
                                        placeholder="Коментарий"
                                        value={newBill.deliveryNote}
                                        onChange={e => setNewBill({...newBill, deliveryNote: e.target.value})}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className={styles.products}>
                            <div className={styles.products__buttons}>
                                <Button 
                                    text="Добавить товар"
                                    onClick={() => {
                                        setAddProductModalVisible(true);
                                    }}
                                    />
                            </div>
                            <Table columns={columns}>
                                {addedProducts != null && addedProducts.length > 0 
                                ?
                                    addedProducts!.map(product => <TableItem inlineStyles={{marginBottom: 10}}>
                                        <>
                                            <TableItemContainer width={columns[0].width}><img src={img} className={styles.img}/></TableItemContainer>
                                            <TableItemContainer width={columns[1].width}><div className={styles.text}>{product.product?.barcode}</div></TableItemContainer>
                                            <TableItemContainer width={columns[2].width}><div className={styles.text}>{product.product?.name}</div></TableItemContainer>
                                            <TableItemContainer width={columns[3].width}><div className={styles.text}>{product.product?.color}</div></TableItemContainer>
                                            <TableItemContainer width={columns[4].width}><div className={styles.text}>{product.product?.capacity}</div></TableItemContainer>
                                            <TableItemContainer width={columns[5].width}><div className={styles.text}>{product.pacakgeQuantitiesSum}</div></TableItemContainer>
                                            <TableItemContainer width={columns[6].width}><div className={styles.text}>{product.allQuantitiesSum}</div></TableItemContainer>
                                            <TableItemContainer width={columns[7].width}>
                                                <Input 
                                                    type="number"
                                                    inlineStylesContainer={{width: 100}}
                                                    onChange={e => 
                                                        setQuantities(prevState =>
                                                            {   
                                                                if (prevState.find(pv => pv.id == product.product?.id)) {
                                                                    return prevState.map(pv => pv.id == product.product?.id 
                                                                        ? {...pv, quantity: NotMore(Number(e.target.value), product.allQuantitiesSum)} 
                                                                        : pv
                                                                        )
                                                                }
                                                                return [...prevState, {id: product.product?.id!, quantity: NotMore(Number(e.target.value), product.allQuantitiesSum)}]
                                                            } 
                                                            )
                                                        } 
                                                    value={quantities!.find(q => q.id == product.product?.id)!.quantity.toString()}
                                                    />
                                            </TableItemContainer>
                                        </>
                                    </TableItem>)
                                : <></>
                                }
                            </Table>
                        </div>
                    </div>
                    <Modal isCloseCloseIcon isOpen={addProductModalVisible} classNames={styles.modalContainer} close={() => setAddProductModalVisible(prevState => !prevState)}>
                        <div style={{width: "100%"}}>
                            <TitleForm title="Выберите товары"/>
                            <div className={styles.searchContainer}>
                                <SearchInput
                                    value={modalSearch}
                                    onChange={e => setModalSearch(e)}
                                    classNames={styles.searchInput}
                                    />
                            </div>
                            <div style={{marginTop: 40}}>
                                {receivedProductsByUser?.map(product => 
                                
                                    <TableItem 
                                        onClick={() => {
                                            if (addedProducts.includes(product!)) {
                                                setAddedProducts(prevState => prevState.filter(el => el.product?.id != product.product!.id));
                                                setQuantities(prevState => prevState.filter(el => el.id != product.product!.id));
                                            }
                                            else {
                                                setAddedProducts(prevState => [...prevState, product!]);
                                                setQuantities(prevState => [...prevState, {id: product.product!.id!, quantity: 0}])
                                            }
                                        }}   
                                        classNames={clsx(addedProducts.filter(el => el.product?.id == product.product!.id).length != 0 ? styles.itemSelected : "", styles.hover)} 
                                        inlineStyles={{marginBottom: 10, width: 690, padding: 20}}
                                        >
                                        <div>
                                            <div style={{display: "flex"}}>
                                                <img src={img} className={styles.modalImg}/>
                                                <div>
                                                    <div className={styles.modalText}>Название: {product.product!.name}</div>
                                                    <div className={styles.modalText}>Артикул: {product.product!.article}</div>
                                                    <div className={styles.modalText}>Штрих-код: {product.product!.barcode}</div>
                                                    <div className={styles.modalText}>Внешний штрих-код: {product.product!.factoryBarcode}</div>
                                                    <div className={styles.modalText}>Остаток на складе: {product.allQuantitiesSum}</div>
                                                    {/* <Button
                                                        inlineStyles={{marginTop: 15}}
                                                        text="Посмотреть описание"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (isVisibleDescriptions.includes(product.product!.id!)) {
                                                                setIsVisibleDescriptions(prevState => prevState.filter(el => el != product.product!.id));
                                                            }
                                                            else {
                                                                setIsVisibleDescriptions(prevState => [...prevState, product.product!.id!]);
                                                            }
                                                        }}
                                                        /> */}
                                                </div>
                                            </div>
                                            {/* {
                                                isVisibleDescriptions.includes(product.product!.id!) &&
                                                <div>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde ex dolore odit impedit nam placeat, in inventore deleniti maxime at quidem ab accusamus odio nisi asperiores tempora repudiandae delectus nostrum.
                                                </div>
                                            } */}
                                        </div>
                                    </TableItem>
                                )}
                            </div>
                            <Button
                                className={styles.button}
                                text="Закрыть"
                                onClick={e => setAddProductModalVisible(false)}
                                />
                        </div>
                    </Modal>
                </>
        </Layout>
    )
};

export {AddingShipmentBillPage};