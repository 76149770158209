import Layout from "widgets/Layout/Layout";
import React, { useLayoutEffect } from "react";
import styles from "./index.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "shared/ui/Button";
import {Container as SharedContainer} from "shared/ui/Container";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { useGetPaymentsBillQuery } from "api";
import { getColorByPaymentBillStatus, getPaymentBillStatus } from "functions/paymentBillStatus";
import Title from "shared/ui/Title/Title";
import { PaymentDetailType } from "enums/paymentType";

const PaymentBillPage = () => {
    const [searchParams] = useSearchParams();
    useLayoutEffect(() => {
        if (!searchParams.has('billId')) {
            navigate(LinkPagesEnum.PaymentBills);
        }
    }, [])
    
    const {data: bill } = useGetPaymentsBillQuery({billId: Number(searchParams.get('billId'))});

    const navigate = useNavigate();

    if (bill == null) {
        return null;
    }
    return (
        <Layout>
                <div className={styles.header}>
                    <div style={{display: "flex"}}>
                        <Button inlineStyles={{marginRight: 10}} text="Назад" onClick={() => navigate(LinkPagesEnum.PaymentBills)}/>
                        <Button text="Экспорт" onClick={() => {}}/>
                    </div>
                </div>
                <div className={styles.body}>
                    <Title inlineClasses={styles.title}>
                        ID: {bill.id}
                    </Title>
                    <SharedContainer inlineStyles={{marginBottom: 10}} inlineClasses={styles.container}>
                        <>
                            {bill.type == 0 &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Юр лицо:</div>
                                <div className={styles.valueText}>{bill.organization?.name}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnSalary &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>ЗП сотруднику:</div>
                                <div className={styles.valueText}>{bill.salaryForEmployee?.user?.email}</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnConsumables &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Покупка расходников</div>
                            </div>}
                            {bill.detailType == PaymentDetailType.ExpensesOnIncidents &&
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Оплата инцидента</div>
                            </div>}
                            <div style={{marginBottom: 10}} className={styles.textBlock}>
                                <div className={styles.nameText}>Общая сумма выплаты:</div>
                                <div className={styles.valueText}>{bill!.paymentBillDetails?.reduce((s, p) => s + p.paymentAmount!, 0)}₽</div>
                            </div>
                            <div className={styles.textBlock}>
                                <div className={styles.nameText}>
                                    Статус оплаты:
                                </div>
                                <div className={styles.valueText} style={{color: getColorByPaymentBillStatus(bill.paymentStatus!)}}>
                                    {getPaymentBillStatus(bill.paymentStatus!)}
                                </div>
                            </div>
                        </>
                    </SharedContainer>
                    {bill.note != null && 
                        <SharedContainer inlineClasses={styles.container}>
                            <div className={styles.textBlock}>
                                <div className={styles.noteNameText}>Коментарий от склада:</div>
                                <div className={styles.noteValueText}>{bill.note}</div>
                            </div>
                        </SharedContainer>
                    }
                    <Button 
                        inlineStyles={{margin: "20px 0"}}
                        text="Оплатить счёт"
                        onClick={() => {}}
                        />
                </div>
                <div>
                    <Title inlineStyles={{marginBottom: 20}}>
                        Подробнее:
                    </Title>
                    <div className={styles.details}>
                        {bill.paymentBillDetails?.map(paymentBill => 
                            <SharedContainer inlineClasses={styles.detail}>
                                <div className={styles.detail__name}>Наименование:</div>
                                <div style={{marginBottom: 10}} className={styles.detail__value}>{paymentBill.name}</div>
                                <div className={styles.detail__name}>Количество:</div>
                                <div style={{marginBottom: 10}} className={styles.detail__value}>{paymentBill.quantity}</div>
                                <div className={styles.detail__name}>Общая сумма:</div>
                                <div className={styles.detail__value}>{paymentBill.paymentAmount}₽</div>
                            </SharedContainer>
                        )}
                    </div>
                </div>
        </Layout>
    )
};

export {PaymentBillPage};