import { ColumnHeaderType, Table } from "widgets/Table";
import styles from "./index.module.scss";
import { useGetWarehouseClientsCooperationRequestsQuery, useGetWarehouseClientsCooperationsQuery, useGetWarehouseEmployeeCooperationRequestsQuery, useGetWarehouseEmployeeCooperationsQuery, useGetWarehouseOrganizationsByUserQuery, usePostWarehouseClientsCreateCooperationRequestMutation, usePostWarehouseEmployeeCreateCooperationRequestMutation } from "api";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import React, { useState } from "react";
import { OptionType, Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { useAppSelector } from "app/store/hooks/redux";
import Modal from "shared/ui/Modal/Modal";
import { Input } from "shared/ui/Input";
import { getLocalDate } from "functions/time";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Организация', width: '15%'},
    {name: 'Id', width: '10%'},
    {name: 'Имя', width: '15%'},
    {name: 'Почта', width: '15%'},
    {name: 'Телефон', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
];

export const tableHeadNameEmployee: Array<ColumnHeaderType> = [
    {name: 'Организация', width: '15%'},
    {name: 'Id', width: '10%'},
    {name: 'Имя', width: '15%'},
    {name: 'Почта', width: '15%'},
    {name: 'Телефон', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
];

export const tableHeadNameRequests: Array<ColumnHeaderType> = [
    {name: 'Id', width: '10%'},
    {name: 'Имя', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
    {name: 'Тип запроса', width: '15%'},
];

export const tableHeadNameEmployeeRequests: Array<ColumnHeaderType> = [
    {name: 'Id', width: '10%'},
    {name: 'Имя', width: '15%'},
    {name: 'Начало сотрудничества', width: '15%'},
    {name: 'Тип запроса', width: '15%'},
];

// const options = ["Клиенты", "Сотрудники", "Клиенты/Запросы", "Сотрудники/Запросы"]
const options: OptionType[] = [{value: "Клиенты", key: 1},{value: "Сотрудники", key: 2}]

const CooperationsPageWarehouseOwner = () => {
    // const {warehouseOwnerId} = useAppSelector(el => el.userReducer);
	const [selectedOptionKey, setSelectedOption] = useState(1);
	const {data: warehouseOrganizations, isFetching: isWarehouseOrganizationsFetching} = useGetWarehouseOrganizationsByUserQuery();
	const {data: clientCooperations, isFetching: isClientCooperationsFetching} = useGetWarehouseClientsCooperationsQuery();
	const {data: employeeCooperations, isFetching: isEmployeeCooperationsFetching} = useGetWarehouseEmployeeCooperationsQuery();
	// const {data: clientCooperationsRequests} = useGetWarehouseClientsCooperationRequestsQuery();
	// const {data: employeeCooperationsRequests} = useGetWarehouseEmployeeCooperationRequestsQuery();
	
	const [idForRequest, setIdForRequest] = useState("");
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>();

	const [isModalSendRequestVisible, setIsModalSendRequestVisible] = useState(false);
	const [isModalSendRequestEmployeeVisible, setIsModalSendRequestEmployeeVisible] = useState(false);

	const [sendRequestClient, {isLoading: isLoadingClient}] = usePostWarehouseClientsCreateCooperationRequestMutation();
	const [sendRequestEmployee, {isLoading: isLoadingEmployee}] = usePostWarehouseEmployeeCreateCooperationRequestMutation();

	const sendRequestHandler = async () => {
		if (selectedOrganizationId == null) {
			return alert("Выберите организацию");
		}
		await sendRequestClient({clientId: Number(idForRequest), organizationId: selectedOrganizationId}).unwrap();
		setIsModalSendRequestVisible(false)
	}
	const sendRequestEmployeeHandler = async () => {
		if (selectedOrganizationId == null) {
			return alert("Выберите организацию");
		}
		await sendRequestEmployee({employeeId: Number(idForRequest), organizationId: selectedOrganizationId}).unwrap();
		setIsModalSendRequestEmployeeVisible(false)
	}
	return (
		<>
			<div className={styles.header}>
				<div style={{display: "flex"}}>
					<Select 
						placeholder="Тип" 
						options={options} 
						inlineStyles={{width: 150, marginRight: 10}} 
						selectedOptionKey={selectedOptionKey} onChange={val => setSelectedOption(val.key)}
						/>
					<Button
						inlineStyles={{marginRight: 10}} 
						text="Отправить запрос сотрудничества клиенту"
						onClick={() => setIsModalSendRequestVisible(true)}
						/>
					<Button
						text="Отправить запрос сотрудничества специалисту"
						onClick={() => setIsModalSendRequestEmployeeVisible(true)}
						/>
				</div>
				<div className={styles.MyId}>
					{
						warehouseOrganizations?.map(org => 
							<div className={styles.myId}>{org.name} ID: {org.id}</div>
						)
					}
					{warehouseOrganizations?.length == 0 && "Нет складских организаций"}
				</div>
			</div>
				{selectedOptionKey == options[0].key
					&& <Table columns={tableHeadName} isFetching={isClientCooperationsFetching}>
						{
							clientCooperations?.map(el => (
								<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
									<>
										<TableItemContainer width={tableHeadName[0].width}><div className={styles.name}> {el.organization?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.client?.id} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadName[2].width}><div className={styles.name}> {el.client?.user?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadName[3].width}><div className={styles.name}> {el.client?.user?.email} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadName[4].width}><div className={styles.name}> {el.client?.user?.telephone} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadName[5].width}><div className={styles.name}> {getLocalDate(el.objectCreateDate!)} </div> </TableItemContainer> 
									</>
								</TableItem>
							))
						}
					</Table>}
				{selectedOptionKey == options[1].key
					&& <Table columns={tableHeadNameEmployee} isFetching={isEmployeeCooperationsFetching}>
						{
							employeeCooperations?.map(el => (
								<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
									<>
										<TableItemContainer width={tableHeadNameEmployee[0].width}><div className={styles.name}> {el.organization?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployee[1].width}><div className={styles.name}> {el.employee?.id} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployee[2].width}><div className={styles.name}> {el.employee?.user?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployee[3].width}><div className={styles.name}> {el.employee?.user?.email} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployee[4].width}><div className={styles.name}> {el.employee?.user?.telephone} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployee[5].width}><div className={styles.name}> {getLocalDate(el.objectCreateDate!)} </div> </TableItemContainer> 
									</>
								</TableItem>
							))
						}
					</Table>}
				{/* {selectedOptionKey == options[2].key
					&& <Table columns={tableHeadNameRequests}>
						{
							clientCooperationsRequests?.map(el => (
								<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
									<>
										<TableItemContainer width={tableHeadNameRequests[0].width}><div className={styles.name}> {el.client?.id} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameRequests[1].width}><div className={styles.name}> {el.client?.user?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameRequests[2].width}><div className={styles.name}> {getLocalDate(el.objectCreateDate!)} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameRequests[3].width}><div className={styles.name}> {el.direction == 0 ? "Исходящий" : "Приходящий"} </div> </TableItemContainer> 
									</>
								</TableItem>
							))
						}
					</Table>}
				{selectedOptionKey == options[3].key
					&& <Table columns={tableHeadNameEmployeeRequests}>
						{
							employeeCooperationsRequests?.map(el => (
								<TableItem hover inlineStyles={{marginBottom: 10}} onClick={() => {}}>
									<>
										<TableItemContainer width={tableHeadNameEmployeeRequests[0].width}><div className={styles.name}> {el.employee?.id} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployeeRequests[1].width}><div className={styles.name}> {el.employee?.user?.name} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployeeRequests[2].width}><div className={styles.name}> {getLocalDate(el.objectCreateDate!)} </div> </TableItemContainer> 
										<TableItemContainer width={tableHeadNameEmployeeRequests[3].width}><div className={styles.name}> {el.direction == 0 ? "Исходящий" : "Приходящий"} </div> </TableItemContainer> 
									</>
								</TableItem>
							))
						}
					</Table>} */}
			<Modal 
				isLoader={isLoadingClient}
				isCloseCloseIcon
				isOpen={isModalSendRequestVisible}
				close={() => setIsModalSendRequestVisible(false)}
				>
				<div>
					<Button text="Закрыть" onClick={() => setIsModalSendRequestVisible(false)} inlineStyles={{margin: "0 auto 20px auto"}}/>
					<Select
						inlineStyles={{marginBottom: 10}}
						placeholder="Складская организация"
						options={warehouseOrganizations?.map(organization => ({key: organization.id!, value: organization.name!}))}
						selectedOptionKey={selectedOrganizationId}
						onChange={val => setSelectedOrganizationId(val.key)}
						/>
					<Input
						value={idForRequest}
						type="number"
						placeholder="Id Клиента"
						onChange={e => setIdForRequest(e.target.value)}
						inlineStylesContainer={{marginBottom: 20, width: 300}}
						/>
					<Button text="Отправить" onClick={sendRequestHandler} inlineStyles={{margin: "0 auto"}}/>
				</div>
			</Modal>
			<Modal 
				isLoader={isLoadingEmployee}
				isCloseCloseIcon
				isOpen={isModalSendRequestEmployeeVisible}
				close={() => setIsModalSendRequestEmployeeVisible(false)}
				>
				<div>
					<Button text="Закрыть" onClick={() => setIsModalSendRequestEmployeeVisible(false)} inlineStyles={{margin: "0 auto 20px auto"}}/>
					<Select
						inlineStyles={{marginBottom: 10}}
						placeholder="Складская организация"
						options={warehouseOrganizations?.map(organization => ({key: organization.id!, value: organization.name!}))}
						selectedOptionKey={selectedOrganizationId}
						onChange={val => setSelectedOrganizationId(val.key)}
						/>
					<Input
						value={idForRequest}
						type="number"
						placeholder="Id Специалиста"
						onChange={e => setIdForRequest(e.target.value)}
						inlineStylesContainer={{marginBottom: 20, width: 300}}
						/>
					<Button text="Отправить" onClick={sendRequestEmployeeHandler} inlineStyles={{margin: "0 auto"}}/>
				</div>
			</Modal>
		</>
	);
};

export {
	CooperationsPageWarehouseOwner
}