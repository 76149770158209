import { DifProduct } from "api";
import { IsArrayIsNotEmpty } from "./array";

export const getProductQuantity = (product: DifProduct) => {
	let quantity = 0;
	
	if (IsArrayIsNotEmpty(product.productCells))
	{
		product.productCells!.forEach(cell => {
            quantity += cell.quantity!;

			if (IsArrayIsNotEmpty(cell.packageProductCells)) {
                cell.packageProductCells!.forEach(packageCell => {
                    quantity += packageCell.quantity!;
                });
            }
        });
	}

	return quantity;
}

export const getProductNotPackageQuantity = (product: DifProduct) => {
	let quantity = 0;
	
	if (IsArrayIsNotEmpty(product.productCells))
	{
		product.productCells!.forEach(cell => {
            quantity += cell.quantity!;
        });
	}

	return quantity;
}

export const getProductPackageQuantity = (product: DifProduct) => {
	let quantity = 0;
	
	if (IsArrayIsNotEmpty(product.productCells))
	{
		product.productCells!.forEach(cell => {
			if (IsArrayIsNotEmpty(cell.packageProductCells)) {
                cell.packageProductCells!.forEach(packageCell => {
                    quantity += packageCell.quantity!;
                });
            }
        });
	}

	return quantity;
}