import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import { ArrowIcon } from "../icons/icons";
import { IsArrayIsNotEmpty } from "functions/array";
import { Loader } from "../Loader";

export type OptionType = {
    key: number;
    value: string;
}

type Props = {
    isFetching?: boolean;
    nullOptionsText?: string; 
    options: OptionType[] | null | undefined;
    selectedOptionKey?: number | undefined | null;
    onChange: (e: OptionType) => void;
    placeholder: string;
    classNames?: string;
    inlineStyles?: CSSProperties;
}

const Select = ({placeholder, options, selectedOptionKey, onChange, classNames, inlineStyles, nullOptionsText = "Нет записей", isFetching}: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const valueByKey = options?.find(x => x.key == selectedOptionKey)?.value;
    const isOptionsNotNull = IsArrayIsNotEmpty(options);
    const isOptionSelected = (selectedOptionKey != null && valueByKey?.length != null) || !isOptionsNotNull;

    return (
        <>
        {isOpen && <div className={styles.fixed} onClick={() => setIsOpen(false)}/>}
        <div className={clsx(styles.container, classNames, isOptionSelected && styles.selected, isOpen && styles.opened)} style={inlineStyles} onClick={e => {
            e.stopPropagation(); 
            if (isOptionsNotNull) 
            {
                setIsOpen(!isOpen)
            }
        }}>
            {isFetching && <Loader inlineStyles={{width: 15, height: 15, borderWidth: 1}}/>}
            <div className={styles.top}>
                <div className={clsx(styles.placeholder)}>{placeholder}</div>
                {isOptionSelected || !isOptionsNotNull ? <div className={styles.selectedOptionWithArrow}>
                    {isOptionsNotNull ? valueByKey : nullOptionsText} 
                        {isOptionsNotNull &&
                        <div className={styles.arrow}>
                            <ArrowIcon fill="#322C36" width="6" height="3" inlineStyles={{transform: isOpen ? "rotate(180deg)" : "rotate(0)" + "translateY(1px)" }}/>
                        </div>}
                    </div>
                    :
                    <div className={styles.arrow}>
                        <ArrowIcon fill="#cacaca" width="6" height="3" inlineStyles={{transform: isOpen ? "rotate(180deg)" : "rotate(0)"}}/>
                    </div>
                }
                
            </div>
            {
                isOpen && isOptionsNotNull
                    && (
                        <div className={styles.bottom}>
                            {options!.map(el => {
                                return (
                                    <div
                                        onClick={() => onChange(el)} 
                                        className={clsx(styles.option, selectedOptionKey == el.key && styles.selectedOption)}
                                        >
                                        {el.value}
                                    </div>
                                )
                            })}
                        </div>
                        )
            }
        </div>
        </>
    );
}

export {
    Select
};