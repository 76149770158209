import { CSSProperties, ReactNode, useEffect } from "react";
import styles from "./Modal.module.scss";
import { CloseIcon } from "../icons/icons";
import clsx from "clsx";
import { Loader } from "../Loader";

type Props = {
    children: ReactNode,
    title?: string | null,
    toClose?: string,
    isOpen?: boolean,
    close: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isCloseCloseIcon?: boolean,
    inlineStyles?: CSSProperties;
    classNames?: string;
    isLoader?: boolean;
    onOpen?: () => void;
};

const Modal = ({classNames, inlineStyles, children, close, isOpen, isCloseCloseIcon, isLoader, onOpen} : Props) => {

    useEffect(() => {
        if (isOpen && onOpen) {
            onOpen();
        }
    }, [isOpen])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") 
            {
                close();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handlerClose = (event: React.SyntheticEvent) => {
        event.preventDefault();
        close();
    };

    // todo 
    return (
        <div className={isOpen ? styles.modalWrapper : styles.modalClose} onClick={close}>
            <div onClick={e => e.stopPropagation()} className={clsx(styles.modal, styles.scroll, classNames)} style={inlineStyles}>
                {!isCloseCloseIcon &&
                <div className={styles.header}>
                    <div className={styles.close} onClick={(event) => handlerClose(event)}>
                        <CloseIcon/>
                    </div>
                </div>
                }
                {children}
                {isLoader && <Loader/>}
            </div>
        </div>
    );
};

export default Modal;