import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Modal from "shared/ui/Modal/Modal";
import InspectionProduct from "../../components/InspectionProduct/InspectionProduct";
import {Button} from "shared/ui-mobile/Button";
import styles from "./Inspection.module.scss";
import { useGetProcessesPackageProcessModelQuery, useGetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessQuery, useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery, usePostProcessesPackageCompleteMutation, usePostProcessesPackageRedactProcessEmployeeMutation } from "api";
import { getPercentColor } from "functions/color";
import { LinkPagesEnum } from "app/store/enums/linkPages";

export type ProcessRealTimeEmployeeType = {
    processId: number;
    employeeId: number;
    name: string | null;
    email: string;
};

const PackageProcessPage = () => { 
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [processId] = useState(Number.parseInt(searchParams.get("processId")!));

    const [lastBarcode, setLastBarcode] = useState("");
    const [barcode, setBarcode] = useState("");
    const [isSecond, setIsSecond] = useState(false);
    const [isSecond2, setIsSecond2] = useState(false);
    const [isModalForEndInspectionOpen, setIsModalForEndInspectionOpen] = useState(false);
    const { data: inspectionProcessModel, refetch } = useGetProcessesPackageProcessModelQuery({processId: processId});
    const [redactProcessEmployeeQuery] = usePostProcessesPackageRedactProcessEmployeeMutation();
    const {data: processRealTimeEmployees, isError: isRealTimeEmployeeError} = useGetProcessesPackageSetEmployeeInProcessAndGetEmployeesInProcessQuery({processId: processId}, {pollingInterval: 3000});

    useEffect(()=>{
        if (isSecond) {
            setLastBarcode(barcode);
            setIsSecond(false);
            setBarcode("");
        }
    },[isSecond]);

    useEffect(() => {
        if (isSecond2) {
            setLastBarcode(barcode);
            setIsSecond2(false);
            setBarcode("");

            var productBillByBarcode = inspectionProcessModel?.products?.find(item => item.barcode == barcode);
              
            if (!productBillByBarcode) {
                productBillByBarcode = inspectionProcessModel?.products?.find(item => item.factoryBarcode == barcode)
                if (!productBillByBarcode)
                    return;
            }

            redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: productBillByBarcode.productProcessId, isIncrement: true}})
            .then(
                () => {
                    refetch();
                }
            );
        }
    }, [isSecond2]);

    const onIncrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId, isIncrement: true}})
        refetch();
    }

    const onDecrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }
        
        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[index].productProcessId, isIncrement: false}})
        refetch();
    }

    const [completeProcess] = usePostProcessesPackageCompleteMutation();
    const onSubmit = async (requiredFull: boolean) => {
        try {
            await completeProcess({inspectionCompleteModel: {processId: processId, requiredFull: requiredFull}}).unwrap();
            navigate(LinkPagesEnum.Processes);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    const [isModalOpened, setIsModalOpened] = useState(false);

    const [ awaitQuantity, setAwaitQuantity ] = useState(0);
    const [ quantity, setQuantity ] = useState(0);
    const [ quantityValue, setQuantityValue] = useState(0);
    const [ indexSelectedElement, setIndexSelectedElement] = useState(0);
    const [isAdd, setIsAdd] = useState(true);

    const onSomeIncrementPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: inspectionProcessModel?.products[indexSelectedElement].productProcessId, isIncrement: true, quantity: quantityValue}});
        refetch();
        
        setQuantity(inspectionProcessModel!.products[indexSelectedElement]!.acceptanceQuantity! + quantityValue);
    }

    const onSetPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        const selectedProduct = inspectionProcessModel?.products[indexSelectedElement];

        if (quantityValue > selectedProduct.acceptanceQuantity!)
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId, isIncrement: true, quantity: quantityValue - selectedProduct.acceptanceQuantity!}});
        }
        else 
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel: {processBillProductId: selectedProduct.productProcessId, isIncrement: false, quantity: selectedProduct.acceptanceQuantity! - quantityValue}});
        }
        refetch();
        
        setQuantity(quantityValue);
    }

    const inputRef = useRef<HTMLInputElement | null>(null);
    if (!isModalOpened)
    {
        inputRef?.current?.focus?.();
    }
    const isFullBill = () => {
        if (inspectionProcessModel?.products?.find(pr => pr.quantity != pr.acceptanceQuantity) != null) {
            return false;
        }
        return true;
    }

    if (inspectionProcessModel == null) {
        return <div></div>;
    }

    return (
        <div className={styles.inspection}>
            <div className={styles.employeesContainer}>
                <div className={styles.employeesTitle}>
                    Кто ещё в этом процессе:
                </div>
                {!isRealTimeEmployeeError ? 
                    <div>
                        {
                            processRealTimeEmployees?.map((el, idx) => <span className={styles.employee} key={el.employeeId}><span className={styles.number}>{idx + 1}. </span>{el.name} ({el.email})</span>)
                        }
                    </div>
                    : <div>
                        Ошибка
                    </div>}
            </div>
            <div className={styles.percentBar} style={{backgroundColor: getPercentColor(inspectionProcessModel!.percent!)}}>
                {inspectionProcessModel?.percent}%
            </div>
            <div className={styles.topButttonsContainer}>
                <Button
                    text="На главную"
                    onClick={()=> navigate("/")}
                />
                <Button
                    text="Сгруппировать"
                    onClick={()=> {}}
                />
            </div>
            <div className={styles.inspectionEnd}>
                <div className={styles.productsContainer}>
                    {
                        inspectionProcessModel?.products?.map((el,index) => <InspectionProduct 
                            name={el.name!} 
                            imgSrc={el.images ? el.images[0] : "assets/images/box.png" }
                            article={el.article!} 
                            barcode={el.barcode!} 
                            factoryBarcode={el.factoryBarcode!}
                            awaitQuantity={el.quantity!}
                            quantity={el.acceptanceQuantity!}
                            onIncrement={()=> onIncrementPress(index)}
                            onDecrement={()=> onDecrementPress(index)}
                            onAdd={() => {
                                setAwaitQuantity(el.quantity!);
                                setQuantity(el.acceptanceQuantity!);
                                setIsModalOpened(true);
                                setIndexSelectedElement(index);
                                setIsAdd(true);
                            }}
                            onSet={() => {
                                setAwaitQuantity(el.quantity!);
                                setQuantity(el.acceptanceQuantity!);
                                setIsModalOpened(true);
                                setIndexSelectedElement(index);
                                setIsAdd(false);
                            }}
                            />
                        )
                    }
                </div>
                
                <Modal
                    isCloseCloseIcon={true}
                    inlineStyles={{padding:"10px 12px 20px 12px"}}
                    close={() => setIsModalOpened(false)} 
                    isOpen={isModalOpened}
                    >
                        <>
                        <Button 
                            text="Закрыть"
                            onClick={() => {
                                setAwaitQuantity(0);
                                setQuantity(0);
                                setIsModalOpened(false);
                            }}
                        />
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <tbody>
                                    <tr>
                                        <td>Ожид. кол-во</td>
                                        <td>Скан. кол-во</td>
                                        <td>Ост. кол-во</td>
                                    </tr>
                                    <tr>
                                        <td>{awaitQuantity}</td>
                                        <td>{quantity}</td>
                                        <td>{awaitQuantity - quantity}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <input 
                            value={quantityValue}
                            onChange={(e) => {
                                if (Number(e.target.value)) {
                                    setQuantityValue(Number(e.target.value))
                                } else {
                                    setQuantityValue(0)
                                }
                            }}
                            className={styles.inputQuantityValue}
                        />
                        <Button 
                            text={isAdd ? "Добавить" : "Установить"}
                            onClick={() => {
                                isAdd 
                                    ? onSomeIncrementPress()
                                    : onSetPress();
                                setQuantityValue(0);
                            }}
                        />
                        </>
                </Modal>
                <Modal
                    inlineStyles={{maxWidth: "90%"}}
                    isCloseCloseIcon
                    close={() => setIsModalForEndInspectionOpen(false)} 
                    isOpen={isModalForEndInspectionOpen}
                    >
                        <Button 
                            text="Закрыть"
                            onClick={() => setIsModalForEndInspectionOpen(false)}
                        />
                        <div className={styles.modalText}>
                            Завершить упаковку с расхождениями ? 
                        </div>
                        <Button 
                            text="Да"
                            onClick={() => onSubmit(false)}
                        />
                </Modal>
            </div>
            <div className={styles.bottomButtonsContainer}>
                <Button 
                    onClick={() => {
                        if (isFullBill()) {
                            onSubmit(true);
                        }
                        else {
                            setIsModalForEndInspectionOpen(true);
                        }
                    }} 
                    text="Завершить упаковку"
                    />
            </div>
        </div>
    )
};

export {PackageProcessPage};