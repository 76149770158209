import styles from "./Inspection.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { BillStatusEnum } from "../model";
import {Button} from "shared/ui-mobile/Button";
import { useNavigate } from "react-router";
import TableSelect from "../components/TableSelect/TableSelect";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { StoragePlaceModel, useGetBillsGetAcceptanceBillsByProductBarcodeQuery, useGetBillsQuery, useGetCellsQuery, usePostProcessesAddProcessMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { TerminalScan } from "widgets/TerminalScan";
import { useAppSelector } from "app/store/hooks/redux";
import { NavigateButton } from "shared/ui/NavigateButton";
import Title from "shared/ui/Title/Title";
import { BillType } from "enums/billType";

enum Stage {
    Zero = 0,
    First = 1,
    Second = 2,
    Third = 3,
}

const InspectionPage = () => {
    const [stage, setStage] = useState(Stage.First);
    const [selectedAcceptanceCell, setSelectedAcceptanceCell] = useState<StoragePlaceModel | null>();
    
    const {employeeWarehouseId} = useAppSelector(state => state.userReducer);

    const { data: acceptanceCells } = useGetCellsQuery({cellType: CellTypeEnum.Acceptance, warehouseId:employeeWarehouseId!}, {skip: employeeWarehouseId == null});
    const { data: acceptanceBills } = useGetBillsQuery({billStatus: BillStatusEnum.New, billType: BillType.Acceptance, warehouseId: employeeWarehouseId!}, {skip: employeeWarehouseId == null});

    const [scannedBarcode, setScannedBarcode] = useState("");
    const { data: acceptanceBillsByProductBarcode, isSuccess: isAcceptanceBillsByProductBarcodeSuccess } = useGetBillsGetAcceptanceBillsByProductBarcodeQuery({barcode: scannedBarcode}, {skip: stage != Stage.Second || scannedBarcode == ""});

    const navigate = useNavigate();

    const [createProcess] = usePostProcessesAddProcessMutation();

    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedAcceptanceCell?.id,
            }).unwrap();

            navigate({pathname: LinkPagesEnum.InspectionProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(()=> {
        if (isAcceptanceBillsByProductBarcodeSuccess) 
        {
            if (acceptanceBillsByProductBarcode != undefined && acceptanceBillsByProductBarcode?.length > 0) 
            {
                setStage(Stage.Third);
            }
            else 
            {
                alert("По данному баркоду товара нет приходных накладных");
            }
        }
    }, [acceptanceBillsByProductBarcode])

    useEffect(()=> {
        if (acceptanceCells?.length == 1)
        {
            setStage(Stage.Second);
            setSelectedAcceptanceCell(acceptanceCells[0]);
        }
    }, [acceptanceCells])
    const [isModalOpened, setIsModalOpened] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    if (!isModalOpened)
    {
        inputRef?.current?.focus?.();
    }

    if (employeeWarehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите склад в профиле</Title>
            <NavigateButton
                to={LinkPagesEnum.UserProfile}
                text="Перейти в профиль"
                />
        </div>;
    }
    return (
        <div className={styles.container}>
            <Button
                classNames={styles.toMain}
                text="На главную"
                onClick={()=> navigate("/")}
            />
            {stage == Stage.First && 
                <>
                    <div className={styles.text}>
                        Просканируйте ячейку приёмки
                    </div>
                    <div>
                        или
                    </div>
                    <TableSelect
                        options={acceptanceCells?.map(el => { return { name:el.name!.toString(), onClick: () => {
                            setSelectedAcceptanceCell(el);
                            setStage(Stage.Second);
                        }}}) ?? []}
                        title="Выберите из списка"
                    />
                </>
            }
            {stage == Stage.Second &&
                <>
                    <div className={styles.title}>
                        Ячейка выбрана: {selectedAcceptanceCell?.name}
                    </div>
                    
                    <div className={styles.subTitle}>
                        Отсканируйте товар накладной или выберите её из списка.
                    </div>
                    <TableSelect 
                        options={acceptanceBills
                            ?.filter(el => el.status != BillStatusEnum.Working)
                            .map(el => { return { name:el.id!.toString(), onClick: () => createProcesshandler(el.id!)}}) ?? []}
                        title="Выберите накладную"
                    />
                    <TerminalScan
                        isFocus
                        onChange={barcodeVal => setScannedBarcode(barcodeVal)}
                        />
                </>
            }
            {stage == Stage.Third &&
                <>

                    <div className={styles.title}>
                        Ячейка выбрана: {selectedAcceptanceCell?.name}
                    </div>
                    
                    <div className={styles.subTitle}>
                        Накладные по данному баркоду, выберите нужную.
                    </div>

                    <TableSelect 
                        options={acceptanceBillsByProductBarcode!
                            .map(el => { return { name:el.id!.toString(), onClick: () => createProcesshandler(el.id!)}}) ?? []}
                        title="Выберите накладную"
                    />
                </>
            }
        </div>
    )
};

export {InspectionPage};