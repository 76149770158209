import React,{ useEffect, useRef, useState } from "react";
import {Button} from "shared/ui-mobile/Button";
import { BillStatusEnum, ProductsBillsType } from "../Inspection/model";
import styles from "./Ship.module.scss";
import { useNavigate } from "react-router";
import TableSelect from "../Inspection/components/TableSelect/TableSelect";
import { StoragePlaceModel, useGetBillsGetShipmentBillsByProductBarcodeQuery, useGetBillsQuery, useGetCellsQuery, usePostProcessesAddProcessMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { CellTypeEnum } from "enums/cellTypeEnum";
import { useAppSelector } from "app/store/hooks/redux";
import { NavigateButton } from "shared/ui/NavigateButton";
import Title from "shared/ui/Title/Title";

enum Stage {
    Zero = 0,
    First = 1,
    Second = 2,
    Third = 3,
}

const ShipPage = () => {
    const [lastBarcode, setLastBarcode] = useState("");
    const [cellId, setCellId] = useState("");
    const [stage, setStage] = useState(Stage.Zero);
    const [secondSelectedOption, setSecondSelectedOption] = useState<number | null>(null);
    const [barcode, setBarcode] = useState("");
    const [isScreenStarted, setIsScreenStarted] = useState(false);
    const [isFocus] = useState(true);
    const [isSecond, setIsSecond] = useState(false);
    const [isSecond2, setIsSecond2] = useState(false);
    const [currentProducts, setCurrentProducts] = useState<Array<ProductsBillsType> | undefined>([]);
    const [isScreenStarted2, setIsScreenStarted2] = useState(false);
    
    const {employeeWarehouseId} = useAppSelector(state => state.userReducer);

    const navigate = useNavigate();
    
    const { data : shipmentCells } = useGetCellsQuery({warehouseId: employeeWarehouseId!, cellType: CellTypeEnum.Shipment}, {skip: employeeWarehouseId == null});
    const { data: shipmentBills } = useGetBillsQuery({billStatus: BillStatusEnum.New, warehouseId: employeeWarehouseId!}, {skip: employeeWarehouseId == null});
    const { data: shipmentBillsByProductBarcode, isSuccess: isAcceptanceBillsByProductBarcodeSuccess } = useGetBillsGetShipmentBillsByProductBarcodeQuery({barcode: lastBarcode}, {skip: stage != Stage.Second || lastBarcode == ""});

    const [ selectedAcceptanceCell, setSelectedAcceptanceCell ] = useState<StoragePlaceModel | null>();
    
    const [createProcess] = usePostProcessesAddProcessMutation();
    const createProcesshandler = async (selectedBillId: number) => {
        try {
            const processId = await createProcess({
                billId: selectedBillId!,
                cellId: selectedAcceptanceCell == null ? 0 : selectedAcceptanceCell!.id
            }).unwrap();

            navigate({pathname: LinkPagesEnum.ShipProcess, search: "?processId=" + processId});
        }
        catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(()=> {
        if (isAcceptanceBillsByProductBarcodeSuccess) 
        {
            if (shipmentBillsByProductBarcode != undefined && shipmentBillsByProductBarcode?.length > 0) 
            {
                setStage(Stage.Third);
            }
            else if (shipmentBillsByProductBarcode?.length == 1) 
            {
                setSecondSelectedOption(shipmentBillsByProductBarcode[0].id!);   
            }
            else 
            {
                alert("По данному баркоду товара нет приходных накладных");
            }
        }
    }, [shipmentBillsByProductBarcode])

    useEffect(()=>{
        if (isSecond) {
            setLastBarcode(barcode);
            setIsSecond(false);
            setBarcode("");
        }
    },[isSecond]);

    useEffect(()=> {
        if (shipmentCells?.length == 1)
        {
            setStage(Stage.Second);
            setSelectedAcceptanceCell(shipmentCells[0]);
        }
    }, [shipmentCells])

    useEffect(()=>{
        if (isSecond2) 
        {
            setLastBarcode(barcode);
            setIsSecond2(false);
            setBarcode("");
            var productBillByBarcode = currentProducts?.find(item => item.product.barcode == barcode);
            
            if (!productBillByBarcode)
            {
                productBillByBarcode = currentProducts?.find(item => item.product.factoryBarcode == barcode)
                if (!productBillByBarcode)
                {
                    return;
                }
            }
            var updatedProduct = {...productBillByBarcode} as ProductsBillsType;
            if (updatedProduct.quantity > updatedProduct.actualQuantity)
            {
                updatedProduct.actualQuantity++;
            }
            
            const newArray = currentProducts!.map(item => item.id !== updatedProduct.id ? item : updatedProduct!);
            setCurrentProducts([...newArray])  
        }
    }, [isSecond2]);

    useEffect(()=>{
        if (stage == Stage.Zero) {
            setStage(Stage.First);
        }
        else if (stage == Stage.First) {
            setCellId(lastBarcode)
            setStage(Stage.Second);
        }
        else if (stage == Stage.Second) {
            setStage(Stage.Third);
        }
    }, [lastBarcode]);

    const onProductBarcodeScaned = (e:React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setBarcode(e.target.value);
        if (!isScreenStarted2) {
            setIsScreenStarted2(true);
                setTimeout(() => {
                setIsScreenStarted2(false);
                setIsSecond2(true);
            }, 100);
        }
    };

    const onScreen = (e:React.ChangeEvent<HTMLInputElement>) => {
        setBarcode(e.target.value);
        if (!isScreenStarted) {
            setIsScreenStarted(true);
            setTimeout(() => {
                setIsScreenStarted(false);
                setIsSecond(true);
            }, 100);
        }
    };
    const [isModalOpened, setIsModalOpened] = useState(false);
    
    const inputRef = useRef<HTMLInputElement | null>(null);
    if (!isModalOpened)
    {
        inputRef?.current?.focus?.();
    }

    if (employeeWarehouseId == null)
    {
        return <div>
            <Title inlineStyles={{marginBottom: 10}}>Выберите склад в профиле</Title>
            <NavigateButton
                to={LinkPagesEnum.UserProfile}
                text="Перейти в профиль"
                />
        </div>;
    }

    return <div className={styles.wrapper}>
        <Button
            classNames={styles.toMain}
            text="На главную"
            onClick={()=> navigate("/")}
        />
        {stage == Stage.First
            &&
            <div className={styles.container}>
                <div className={styles.title}>
                    Просканируйте ячейку отгрузки
                </div>
                <div className={styles.subTitle}>
                    или выберете из списка 
                </div>
                <TableSelect 
                    isTitleVisible={false}
                    options={shipmentCells?.map(el => { return { name: el.name!.toString(), onClick: () => {
                        setSelectedAcceptanceCell(el);
                        setStage(Stage.Second);
                    }}}) ?? []}
                />
                <input 
                    className={styles.input}
                    onChange={onScreen}
                    value={barcode}
                    autoFocus={isFocus}
                    type="text"/>
            </div>  
            
        }
        {stage == Stage.Second
            &&
            <>
                <div className={styles.title}>
                    Ячейка выбрана: {selectedAcceptanceCell?.name}
                </div>
                {
                    shipmentBills?.length != 0 && shipmentBills != undefined ?
                    <>
                        <div className={styles.subTitle}>
                            Отсканируйте товар накладной или выберите её из списка.
                        </div>
                        <TableSelect title="Выберите накладную" options={ shipmentBills?.map(el => {return {name:el.id!.toString(), onClick:() => createProcesshandler(el.id!)}}) || []}/>
                        <input 
                            ref={inputRef}
                            onChange={onProductBarcodeScaned}
                            placeholder="barcod3123e"
                            value={barcode}
                            autoFocus={isFocus}
                            type="text"/>
                    </>
                    : <div className={styles.title}>
                        Нет накладных на отгрузку
                    </div>
                }
            </>
        }
        {
            stage == Stage.Third
            && 
                <>
                    
                    <div className={styles.title}>
                        Ячейка выбрана: {selectedAcceptanceCell?.name}
                    </div>
                    
                    <div className={styles.subTitle}>
                        По данному баркоду несколько приходных накладных, выберите нужную. 
                    </div>
                    <TableSelect 
                        title="Выберите накладную" 
                        options={shipmentBillsByProductBarcode?.map(el => { return {name:el.id!.toString(), onClick:() => createProcesshandler(el.id!)}}) || []}
                        />
                </>
        }
    </div>
};

export {ShipPage};