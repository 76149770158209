import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import RedirectButton from "shared/ui/RedirectButton/RedirectButton";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import Modal from "shared/ui/Modal/Modal";
import { useNavigate } from "react-router-dom";
import {Button} from "shared/ui/Button";
import { userSlice } from "app/store/reducers/UserSlice";
import { useAppDispatch } from "app/store/hooks/redux";
import { Input } from "shared/ui/Input";
import { AuthCredentials, useGetUsersMeQuery, usePostAuthLoginMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";

const AuthorizationPage = () => {
    const [user, setUser] = useState<AuthCredentials>({email: '', password: ''});
    const [addUser, {isLoading, isSuccess : isLogined}] = usePostAuthLoginMutation();
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {setMe,setToken} = userSlice.actions

    const {data, isSuccess} = useGetUsersMeQuery(undefined, {skip: !isLogined });

    const handleAuthorization = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        await addUser({authCredentials: { email: user.email, password: user.password }}).unwrap()
            .then(res => {
                localStorage.setItem("authToken", res.authToken!);
                dispatch(setToken(res.authToken!))
            })
            .catch(error => {
                setUser({ email: '', password: '' });
            })    
    };

    useEffect(() => {
        if (isSuccess)
        {
            dispatch(setMe(data))
            navigate("/")
        }
    },[data])

    return (
        <div className={styles.pageAuthorization}>
            <div className={styles.form__wrapper}>
                {/* {isLoading ? 
                    <Modal>
                        Loading...
                    </Modal> 
                    :
                    null
                } */}
                <TitleForm title="Вход"/>
                <form 
                    className={styles.form__authorization} 
                    onSubmit={(event) => handleAuthorization(event)}
                >
                    <Input
                        value={user.email ?? ""} 
                        onChange={e => setUser({...user, email: e.target.value})}
                        placeholder="E-mail"
                        inlineStylesContainer={{width: "100%", marginBottom: "10px"}}
                    />
                    <Input
                        value={user.password ?? ""} 
                        onChange={e => setUser({...user, password: e.target.value})}
                        placeholder="Пароль"
                        inlineStylesContainer={{width: "100%"}}
                    />
                    <Button onClick={e => {}} text="Войти" inlineStyles={{margin: "20px 0px"}}/>
                    <RedirectButton text="Ещё нет аккаунта ?" buttonName="Регистрация" path={LinkPagesEnum.Registration}/>
                </form>
            </div>
        </div>
    )
};

export { AuthorizationPage };