import React, { useState } from "react";
import styles from "./index.module.scss";
import {Button} from "shared/ui/Button";
import Layout from "widgets/Layout/Layout";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { SearchInput } from "shared/ui/SearchInput";
import { MultipleSelect } from "shared/ui/MultipleSelect";
import { Sort } from "shared/ui/Sort";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { useGetUsersEmployeesQuery } from "api";
import { OptionType } from "shared/ui/Select";

const columns: ColumnHeaderType[] = [{
  name: "ID",
  width: "25%"
},
{
  name: "Почта",
  width:"25%"
},
{
  name: "Склад",
  width: "25%"
}]

const options: OptionType[] = [
  {
    key: 1,
    value: "ID",
  },
  {
    key: 2,
    value: "Почта",
  },
  {
    key: 3,
    value: "Склад",
  },
];

const UsersPage = ({}) =>  {
  const {data: employeesByWarehouse } = useGetUsersEmployeesQuery({warehouseId: 1});

  const [searchText, setSearchText] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<number[] | null>([2]);

  const filteredData = employeesByWarehouse?.filter(el => filterDataEl(searchText, selectedOptions, [
      {value: el.id, key: 1},
      {value: el.user?.email, key: 2},
      {value: el.warehouse?.name, key: 3},
  ]));
  
  const [selectedOption, setSelectedOption] = useState(2);

  const [isDesc, setIsDesc] = useState(true);

  const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
      {value2: el2.id, value1: el1.id, key: 1},
      {value2: el2.user?.email, value1: el1.user?.email, key: 2},
      {value2: el2.warehouse?.name, value1: el1.warehouse?.name, key: 3},
  ], selectedOption, isDesc));

  return (
    <Layout>
      <div style={{display: 'flex', justifyContent:'space-between', marginBottom: 20}}>
          <div style={{display: "flex"}}>
              <Button
                  inlineStyles={{marginRight: 10}}
                  text="Обновить"
                  onClick={() => {}}
                  />
              <Button 
                inlineStyles={{marginRight: 10}}  
                text="Добавить" 
                onClick={() => {}}
                />
              <Button 
                inlineStyles={{marginRight: 10}}  
                text="Редактировать"
                onClick={() => {}}
                />
              <Button 
                text="Заблокировать" 
                onClick={() => {}}
                />
          </div>
          <div style={{display: 'flex'}}>
              <SearchInput 
                  value={searchText} 
                  onChange={setSearchText}
                  inlineStyles={{marginRight: 10}}
                  classNames={styles.searchInput}
                  />
              <MultipleSelect 
                  text="Параметры"
                  options={options} 
                  selectedOptionKeys={selectedOptions} 
                  setSelectedOptions={setSelectedOptions}
                  inlineStyles={{marginRight: 10, width: 135}}
                  />
              <Sort 
                  options={options}
                  selectedOptionKey={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setIsDesc={setIsDesc}
                  isDesc={isDesc}
                  selectClassNames={styles.select}
                  />
          </div>
      </div>
        <Table columns={columns}>
            {sortedData?.length != 0 ?
                sortedData?.map(el => (
                    <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                        <>
                          <TableItemContainer width={columns[0].width}>{el.id}</TableItemContainer>
                          <TableItemContainer width={columns[1].width}>{el.user?.email}</TableItemContainer>
                          <TableItemContainer width={columns[2].width}>{el.warehouse?.name}</TableItemContainer>
                        </>
                    </TableItem>
            ))
            :  <></>
        }
        </Table>
    </Layout>
  )
}

export {UsersPage};