import React, { useEffect, useState } from "react";
import Modal from "shared/ui/Modal/Modal";
import RedirectButton from "shared/ui/RedirectButton/RedirectButton";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import { useNavigate } from "react-router-dom";
import {Button} from "shared/ui/Button";
import { useAppDispatch } from "app/store/hooks/redux";
import { userSlice } from "app/store/reducers/UserSlice";
import { Input } from "shared/ui/Input";
import styles from "./index.module.scss";
import { AuthCredentials, useGetUsersMeQuery, usePostAuthRegistrationMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { alertsSlice, AlertType } from "app/store/reducers/alertsSlice";
import { Select } from "shared/ui/Select";
import { getUserTypeKey, getUserTypeName } from "functions/userType";

const RegistrationPage = () => {
    const [user, setUser] = useState<AuthCredentials>({email: '', password: '', userType: 0});
    const [confirmPassword, setConfirmPassword] = useState('');

    const [addUser, {isLoading, isSuccess : isLogined}] = usePostAuthRegistrationMutation();
    const {data, isSuccess} = useGetUsersMeQuery(undefined, {skip:!isLogined });
    const dispatch = useAppDispatch();
    const {setMe,setToken} = userSlice.actions

    const navigate = useNavigate();
    const {setAlert, removeAlert} = alertsSlice.actions;

    const handleRegistration = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (user.password === confirmPassword) 
            {
            await addUser({authCredentials: user}).unwrap()
            .then(res => {
                localStorage.setItem("authToken", res.authToken!);
                dispatch(setToken(res.authToken!))
                navigate("/");
            })
            .catch(error => {
                setUser({email: '', password: ''});
                setConfirmPassword('');
            })
        }
        else {
            const alert = {
                type: AlertType.Error,
                message: 'Пароли не совпадают',
                title: '',
                identifier: Math.random().toString()
            };
            dispatch(setAlert(alert))
            
            setTimeout(() => {
                dispatch(removeAlert(alert.identifier))
            }, 2500)
            setConfirmPassword('');
        }
    };

    useEffect(() => {
        if (isSuccess)
        {
            dispatch(setMe(data))
            navigate("/")
        }}, [data])
        
    return (
        <div className={styles.pageRegistration}>
            <div className={styles.form__wrapper}>
            {/* {isLoading ? 
                <Modal>
                    Loading...
                </Modal> 
                :
                null
            } */}
            <TitleForm title="Регистрация"/>
            <form 
                className={styles.form__registration} 
                onSubmit={(event) => handleRegistration(event)}
            >
                <Input
                    value={user.email ?? ""}
                    onChange={e => setUser({...user, email: e.target.value})}
                    placeholder="E-mail"
                    inlineStylesContainer={{width: '100%', marginBottom: '10px'}}
                    />
                <Select
                    selectedOptionKey={user.userType!}
                    options={[{value: getUserTypeName(0), key: 0}, {value: getUserTypeName(1), key: 1}, {value: getUserTypeName(2), key: 2}]}
                    onChange={e => setUser({...user, userType: getUserTypeKey(e.value)})}
                    placeholder="Тип аккаунта"
                    inlineStyles={{width: '100%', marginBottom: '10px'}}
                    />
                <Input
                    value={user.password ?? ""}
                    onChange={e => setUser({...user, password: e.target.value})}
                    placeholder="Пароль"
                    inlineStylesContainer={{width: '100%', marginBottom: '10px'}}
                    />
                <Input
                    value={confirmPassword}
                    onChange={e => {
                        setConfirmPassword(e.target.value);
                    }}
                    placeholder="Повторите пароль"
                    inlineStylesContainer={{width: '100%'}}
                />
                <Button onClick={e => {}} text="Зарегистрироваться" inlineStyles={{margin: "20px 0"}}/>
                <RedirectButton text="Уже есть аккаунт ?" buttonName="Авторизоваться" path={LinkPagesEnum.Authorization}/>
            </form>
            </div>
        </div>
    )
}

export { RegistrationPage };