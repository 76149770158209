import React, { useState } from "react";
import styles from './index.module.scss';
import { MultipleSelect } from "shared/ui/MultipleSelect";
import clsx from "clsx";
import { SearchInput } from "shared/ui/SearchInput";
import { Sort } from "shared/ui/Sort";
import { filterDataEl } from "functions/filter";
import { sortDataEl } from "functions/sort";
import { useGetProductsCellsQuery } from "api";
import { Container } from "shared/ui/Container";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import boxImg from "shared/assets/images/box.png";
import { Button } from "shared/ui/Button";
import { OptionType } from "shared/ui/Select";

type Props = {
    id: number;
    closeModal: () => void;
};

const options: OptionType[] = [
    {value: "Наименование", key: 1},
    {value: "Артикул", key: 2},
    {value: "Заводской ШТ", key: 3},
    {value: "Внешний ШТ", key: 4},
    {value: "Остаток на складе", key: 5},
    {value: "Заполненность ед", key: 6},
    {value: "Заполненность общ", key: 7},
];

const ProductsStorageModal = ({id, closeModal}: Props) => {
    const {data: receivedProductsByCell} = useGetProductsCellsQuery({skip: 0, take: 100, cellId: id});

    const [selectedOptions, setSelectedOptions] = useState<number[] | null>([1]);
    const [searchText, setSearchText] = useState("");

    const filteredData = receivedProductsByCell?.filter(el => filterDataEl(searchText, selectedOptions, [
        {value: el.receivedProduct?.product?.name, key: 1},
        {value: el.receivedProduct?.product?.article, key: 2},
        {value: el.receivedProduct?.barcode, key: 3},
        {value: el.receivedProduct?.product?.factoryBarcode, key: 4},
        {value: el.quantity, key: 5},
        {value: el.occupancyPercentByUnit, key: 6},
        {value: el.occupancyPercent, key: 7},
    ]));
    
    const [selectedOption, setSelectedOption] = useState(1);

    const [isDesc, setIsDesc] = useState(true);

    const sortedData = filteredData?.sort((el1, el2) => sortDataEl([
        {value2: el2.receivedProduct?.product?.name, value1: el1.receivedProduct?.product?.name, key: 1},
        {value2: el2.receivedProduct?.product?.article,  value1: el1.receivedProduct?.product?.article, key: 2},
        {value2: el2.receivedProduct?.barcode, value1: el1.receivedProduct?.barcode, key: 3},
        {value2: el2.receivedProduct?.product?.factoryBarcode, value1: el1.receivedProduct?.product?.factoryBarcode, key: 4},
        {value2: el2.quantity, value1: el1.quantity, key: 5},
        {value2: el2.occupancyPercentByUnit, value1: el1.occupancyPercentByUnit, key: 6},
        {value2: el2.occupancyPercent, value1: el1.occupancyPercent, key: 7},
    ], selectedOption, isDesc));

    return (
        <>
            <TitleForm title="Товары в этой ячейке"/>
            <div className={styles.header}>
                <SearchInput
                    classNames={styles.searchInput}
                    value={searchText}
                    onChange={setSearchText}
                    inlineStyles={{marginRight: 10, width: "52%"}}
                    />
                <MultipleSelect 
                    text="Параметры"
                    options={options} 
                    selectedOptionKeys={selectedOptions} 
                    setSelectedOptions={setSelectedOptions}
                    inlineStyles={{marginRight: 10, width: 135}}
                    />
                <Sort 
                    options={options}
                    selectedOptionKey={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setIsDesc={setIsDesc}
                    isDesc={isDesc}
                    selectClassNames={styles.sort}
                    />
            </div>
            <div className={styles.products}>
                {sortedData?.map(el => {
                    const product = el.receivedProduct!.product!;
                    return (
                        <Container inlineClasses={styles.container}>
                            <>
                                <img
                                    className={styles.img} 
                                    src={product.images 
                                        ? product.images[0] 
                                        : boxImg} alt={product.name ?? ""} 
                                    />
                                <div className={styles.textWrapper}>
                                    <div className={styles.text}>Наименование: {product.name}</div>
                                    <div className={styles.text}>Артикул: {product.article}</div>
                                    <div className={styles.text}>
                                        Заводской ШТ: {product.barcode}
                                    </div>
                                    <div className={styles.text}>Внешний ШТ: {product.factoryBarcode}</div>
                                    <div className={clsx(styles.quantity, styles.text)}>
                                        Остаток на складе: {el.quantity}
                                    </div>
                                    <div className={styles.text}>
                                        Заполненность
                                        <div>
                                            Ед: {el.occupancyPercentByUnit}%
                                        </div>
                                        <div>
                                            Общ: {el.occupancyPercent}%
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Container>
                    )
                })}
                {sortedData?.length == 0 && <div style={{textAlign: "center", margin: "120px 0 180px 0"}}>Нет элементов</div>}
            </div>
            <Button text="Закрыть" onClick={closeModal} inlineStyles={{marginTop: 10}}/>
        </>
    )
};

export default ProductsStorageModal;