import Layout from "widgets/Layout/Layout";
import styles from "./index.module.scss";
import { CreateKitModel, DifConsumables, DifProduct, useGetConsumablesQuery, useGetOrganizationsByIdProductsQuery, useGetOrganizationsQuery, usePostProductsImageMutation, usePostProductsKitMutation } from "api";
import React, { useState } from "react";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";
import { Button } from "shared/ui/Button";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { TableItem } from "widgets/Table/components/Item";
import Modal from "shared/ui/Modal/Modal";
import { SearchInput } from "shared/ui/SearchInput";
import TitleForm from "shared/ui/TitleForm/TitleForm";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { ImageSelector } from "features/ImageSelector";
import { NavigateButton } from "shared/ui/NavigateButton";
import { getImageUrl } from "functions/image";
import { getProductQuantity } from "functions/product";

export const tableHeadName: Array<ColumnHeaderType> = [
    {name: 'Изображение', width: '20%'},
    {name: 'Штрихкод', width: '15%'},
    {name: 'Наименование', width: '20%'},
    {name: 'Остаток', width: '10%'},
    {name: "Цвет", width: "10%" },
    {name: 'Количество', width: '10%'},
    {name: 'Расходник/Кол-во', width: '15%'},
];

const AddingKitPage = () => {
    const {data: consumables} = useGetConsumablesQuery(undefined);
    
    const [createdKit, setCreatedKit] = useState<CreateKitModel>({});
    const {data: organizations} = useGetOrganizationsQuery();

    const {data: productsByOrganization} = useGetOrganizationsByIdProductsQuery({id: createdKit.organizationId!}, {skip: createdKit.organizationId == null});

    const[addProductImage] = usePostProductsImageMutation();

    const [addKit] = usePostProductsKitMutation();

    const [currentImage, setCurrentImage] = useState<File>();
    const [previewImage, setPreviewImage] = useState("");

    const [selects, setSelects] = useState<{c: DifConsumables, count: number}[]>([]);

    const navigate = useNavigate();
    const addKitHandler = async () => {
        try {
            const a = await addKit({
                createKitModel: {...createdKit,
                    childs: addedProducts.map(x => ({
                        producId: x.p.id, 
                        quantity: x.quantity, 
                        createKitConsumableModels: consumablesForAddedproducts?.filter(x2 => x2.pId == x.p.id).map(c => ({cunsumableId: c.selectedConsumableId, quantity: c.count}))
                    })),
                    createKitConsumableModels: selects.map(select => ({cunsumableId: select.c.id!, quantity: select.count!}))
                }
            }).unwrap();

            await addProductImage({
                body:{
                    File: currentImage,
                    ProductId: a.kitId
                }
            }).unwrap();

            setCreatedKit({});
            navigate(LinkPagesEnum.Products);
        } catch (err) {
            // Handle error
        }
    };

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);
    const [addedProducts, setAddedProducts] = useState<{quantity: number, p: DifProduct}[]>([]);
    const [modalSearch, setModalSearch] = useState("");
    const [consumablesForAddedproducts, setConsumablesForAddedproducts] = useState<{pId: number, idx: Date, selectedConsumableId: number, name: string, count: number}[]>([]);

    return (
        <Layout>
                <>
                    <div style={{display: "flex"}}>
                        <NavigateButton
                            to={LinkPagesEnum.Products}
                            text="Назад"
                            inlineStyles={{marginRight: 10}} 
                            className={styles.headerButtons}
                            />
                        <Button text="Создать" className={styles.headerButtons} onClick={addKitHandler}/>
                    </div>
                    <div style={{display: "flex", alignItems: "start"}}>
                        <ImageSelector
                            inlineClasses={styles.imageSelector}
                            previewImage={previewImage}
                            setPreviewImage={setPreviewImage}
                            setCurrentImage={setCurrentImage}
                            />
                            <div className={styles.modal__fieldsContainer}>
                                <Input placeholder="Название" value={createdKit.name} onChange={e => setCreatedKit({...createdKit, name: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Артикул" value={createdKit.article} onChange={e => setCreatedKit({...createdKit, article: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Цвет" value={createdKit.color} onChange={e => setCreatedKit({...createdKit, color: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Внешний баркод" value={createdKit.factoryBarcode} onChange={e => setCreatedKit({...createdKit,factoryBarcode: e.target.value })} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Заводской баркод" value={createdKit.barcode} onChange={e => setCreatedKit({...createdKit, barcode: e.target.value})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Input placeholder="Цена обслуживания" value={createdKit.serviceProductPrice?.toString()} onChange={e => setCreatedKit({...createdKit, serviceProductPrice: Number(e.target.value)})} inlineStyles={{marginBottom: 10}} classNamesContainer={styles.modal__field}/>
                                <Select placeholder="Организация" options={organizations?.map(el => ({value: el.name!, key: el.id!})) ?? []} selectedOptionKey={createdKit.organizationId} onChange={val => setCreatedKit({...createdKit, organizationId: val.key})} classNames={styles.modal__field}/>
                            </div>
                            <div className={styles.kitConsumables}>
                                {selects?.map((el, idx) => 
                                    <div className={styles.kitConsumable}>
                                        <Select classNames={styles.kitConsumableSelect} selectedOptionKey={el.c.id} options={consumables!.map(x => ({value: x.name!, key: x.id!}))} onChange={(val) => {setSelects(prevState => {
                                            const selectedOption = consumables!.find(x => x.id === val.key)!;
                                            const newArr = prevState.map((el2, idx2) => idx == idx2 ? {c: selectedOption, count: 0} : el2);
                                            return newArr;
                                        })}} placeholder={"Расходник " + (idx + 1)}/>
                                        <Input classNamesContainer={styles.kitConsumableInput} placeholder="Кол-во" type="number" value={el.count.toString()} onChange={e => setSelects(prev2 => prev2.map((x4, idx2) => idx == idx2 ? {...x4, count: Number(e.target.value)} : x4))}/>
                                    </div>)}
                                {/* {consumables != null && <Select classNames={styles.kitConsumableSelect} placeholder="Расходник" selectedOption={currentSelect!.name!} options={consumables!.map(x => x.name!)} onChange={val => setCurrnetSelect(consumables!.find(x => x.name  == val)!)}/>} */}
                                <Button text="Добавить расходник" onClick={() => setSelects(prevState => [...prevState, {c: {name: ""}, count: 0}])}/>
                            </div>
                    </div>
                    <Button text="Добавить товар" className={styles.addProductButton} onClick={() => setAddProductModalVisible(true)}/>
                    <Table columns={tableHeadName}>
                        {
                            addedProducts?.map(el => (
                                <TableItem inlineStyles={{marginBottom: 10}} onClick={() => {}}>
                                    <>
                                        <TableItemContainer width={tableHeadName[0].width}><img className={styles.image} src={getImageUrl(el.p.imageLinks)} alt={el.p.name!} /> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[1].width}><div className={styles.name}> {el.p.barcode} </div> </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[2].width}>{el.p.name} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[3].width}>{el.p.color} </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[4].width}>0 </TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[5].width}><Input type="number" inlineStyles={{width: 63}} onChange={val => setAddedProducts(prevState => prevState.map(x => x.p.id == el.p.id ? {...x, quantity: Number(val.target.value)} : x))} value={el.quantity.toString()}/></TableItemContainer> 
                                        <TableItemContainer width={tableHeadName[6].width}>
                                            <div>
                                                {consumablesForAddedproducts.filter(x => x.pId == el.p.id && x.name != "").map(f => <div>{f.name}/{f.count} ед.</div>)}
                                            </div>
                                        </TableItemContainer> 
                                    </>
                                </TableItem>
                            ))
                        }
                    </Table>
                    <Modal isCloseCloseIcon isOpen={addProductModalVisible} classNames={styles.modalContainer} close={() => setAddProductModalVisible(prevState => !prevState)}>
                        <div style={{width: "100%"}}>
                            <TitleForm title="Выберите товары"/>
                            <div className={styles.searchContainer}>
                                <SearchInput
                                    value={modalSearch}
                                    onChange={e => setModalSearch(e)}
                                    classNames={styles.searchInput}
                                    />
                            </div>
                            <div style={{marginTop: 40}}>
                                {productsByOrganization?.map((product) => 
                                    <TableItem 
                                        onClick={() => {
                                            if (addedProducts.filter(product2 => product2.p.id == product.id).length > 0) {
                                                setAddedProducts(prevState => prevState.filter(el => el.p.id != product.id));
                                            }
                                            else {
                                                setAddedProducts(prevState => [...prevState, {p: product,  quantity: 0}]);
                                            }
                                        }}   
                                        classNames={clsx(addedProducts.filter(el => el.p.id == product.id).length != 0 ? styles.itemSelected : "", styles.hover)} 
                                        inlineStyles={{marginBottom: 10, width: 690, padding: 20}}
                                        >
                                        <div style={{width: "100%"}}>
                                            <div style={{display: "flex", marginBottom: 20}}>
                                                <img src={getImageUrl(product.imageLinks)} alt={product.name!} className={styles.modalImg}/>
                                                <div>
                                                    <div className={styles.modalText}>Название: {product.name}</div>
                                                    <div className={styles.modalText}>Артикул: {product.article}</div>
                                                    <div className={styles.modalText}>Штрих-код: {product.barcode}</div>
                                                    <div className={styles.modalText}>Внешний штрих-код: {product.factoryBarcode}</div>
                                                    <div className={styles.modalText}>Остаток на складе: {getProductQuantity(product)}</div>
                                                    <div>
                                                        Расходники:
                                                        {consumablesForAddedproducts.filter(x => x.pId == product.id).map(f => <div>{f.name}/{f.count} ед.</div>)}
                                                    </div>
                                                </div>
                                            </div>
                                            {consumables != null && consumables!.length > 0 ?
                                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                                <div>
                                                    {consumablesForAddedproducts?.filter(c => c.pId == product.id)?.map((el, idx) => 
                                                        <div className={styles.kitConsumable}>
                                                            <Select classNames={styles.kitConsumableSelect} selectedOptionKey={el.selectedConsumableId} options={consumables!.map(x => ({value: x.name!, key: x.id!}))} onChange={(val) => {
                                                                const selectedSelect = consumablesForAddedproducts.find(c => c.pId == product.id && c.idx == el.idx)!;
                                                                const selectedCon = consumables?.find(a => a.id == val.key)!;
                                                                
                                                                setConsumablesForAddedproducts(prevState2 => prevState2.map(c => c!.pId == selectedSelect!.pId && c!.idx == selectedSelect!.idx ? ({...selectedSelect, selectedConsumableId: selectedCon!.id!, name: selectedCon!.name!}) : c));
                                                            }} placeholder={"Расходник " + (idx + 1)}/>
                                                            <Input classNamesContainer={styles.kitConsumableInput} placeholder="Кол-во" type="number" value={el.count.toString()}  onChange={(val) => {
                                                                const selectedSelect = consumablesForAddedproducts.find(c => c.pId == product.id && c.idx == el.idx)!;
                                                                
                                                                setConsumablesForAddedproducts(prevState2 => prevState2.map(c => c!.pId == selectedSelect!.pId && c!.idx == selectedSelect!.idx ? ({...selectedSelect, count: Number(val.target.value)}) : c));
                                                            }}/>
                                                        </div>)}
                                                </div>
                                                <Button text="Добавить расходник" onClick={() => setConsumablesForAddedproducts(prevState => [...prevState, {idx: (new Date()), pId: product!.id!, selectedConsumableId: 0, count: 0, name: ""}])}/>
                                            </div>: null}
                                        </div>
                                    </TableItem>
                                )}
                            </div>
                            <Button
                                className={styles.button}
                                text="Закрыть"
                                onClick={e => setAddProductModalVisible(false)}
                                />
                        </div>
                    </Modal>
                </>
        </Layout>
    );
};

export {
    AddingKitPage,
};