import { ArrowIcon } from "shared/ui/icons/icons";
import { Container } from "shared/ui/Container";
import React from "react";
import styles from "./index.module.scss";
import { NavigateButton } from "shared/ui/NavigateButton";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { Button } from "shared/ui/Button";
import { useGetEventsQuery } from "api";
import { ColumnHeaderType, Table } from "widgets/Table";
import { TableItem } from "widgets/Table/components/Item";
import { TableItemContainer } from "widgets/Table/components/Item/components/Container";
import { getLocalDate } from "functions/time";

const columns: ColumnHeaderType[] = [
    {width: "15%", name: "Создано"},
    {width: "15%", name: "ID"},
    {width: "20%", name: "Название"},
    {width: "35%", name: "Описание"},
]

var date = new Date().toISOString();

const HomeClient = () => {
    const {data: events, isFetching: isEventsFetching} = useGetEventsQuery();

    return (
    <div className={styles.left}>
        <div className={styles.container}>
            <Container inlineStyles={{marginRight: 20, width: "40%"}}>
                <>
                    <div className={styles.title} style={{marginBottom: 10}}>
                        Создавайте новые накладные
                        опережающего развития
                    </div>
                    <Button
                        text="Создать накладную"
                        onClick={() => {}}
                        />
                </>
            </Container>
            <Container inlineStyles={{width: "60%"}}>
                <>
                    <div className={styles.title}>
                        Узнайте, как работает система
                    </div>
                    <div className={styles.description} style={{marginBottom: 50, marginTop: 3}}>
                        Это займёт всего пару минут
                    </div>
                    <div className={styles.goToInfo}>
                        Подробнее 
                        <ArrowIcon  fill="#000" inlineStyles={{rotate: "-90deg", width: "9px"}}/>
                    </div>
                </>
            </Container>
        </div>
        <Container inlineClasses={styles.fastButtons}>
            <>
                <NavigateButton
                    text="Создать товар"
                    to={LinkPagesEnum.Products}
                    inlineStyles={{marginRight: 10}}
                    />
                <Button
                    text="Экспорт товаров"
                    onClick={() => {}}
                    inlineStyles={{marginRight: 10}}
                    />
            </>
        </Container>
        <div>
            <div className={styles.title} style={{marginBottom: 20}}>
                События
            </div>
            <Table columns={columns} isFetching={isEventsFetching}>
                {events?.map(b => 
                    <TableItem hover onClick={() => {
                    }}>
                        <TableItemContainer width={columns[0].width}>{getLocalDate(b.objectCreateDate)}</TableItemContainer>
                        <TableItemContainer width={columns[1].width}>{b.id}</TableItemContainer>
                        <TableItemContainer width={columns[2].width}>{b.title}</TableItemContainer>
                        <TableItemContainer width={columns[3].width}>{b.description}</TableItemContainer>
                    </TableItem>
                )}
            </Table>
        </div>
    </div>
    );
};

export {
    HomeClient,
}