import React,{ useEffect, useState } from "react";
import {Button} from "shared/ui-mobile/Button";
import styles from "./index.module.scss";
import ShipProductItem from "../../components/ShipProductItem";
import { useNavigate } from "react-router";
import Modal from "shared/ui/Modal/Modal";
import { useSearchParams } from "react-router-dom";
import { useGetProcessesInspectionProcessModelQuery, useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery, usePostProcessesInspectionCompleteMutation, usePostProcessesRedactProcessEmployeeMutation } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";
import { getPercentColor } from "functions/color";
import boxImg from "shared/assets/images/box.png";

const ShipProcessPage = () => {
    // const [submitConfirmation, setSubmitConfirmation] = useState(false);
    const [searchParams] = useSearchParams();
    const [processId] = useState(Number.parseInt(searchParams.get("processId")!));

    const { data: inspectionProcessModel, refetch } = useGetProcessesInspectionProcessModelQuery({processId: processId});
    
    const [isModalForEndInspectionOpen, setIsModalForEndInspectionOpen] = useState(false);
    const navigate = useNavigate();

    const [ awaitQuantity, setAwaitQuantity ] = useState(0);
    const [ quantity, setQuantity ] = useState(0);
    const [ quantityValue, setQuantityValue] = useState(0);
    const [ indexSelectedElement, setIndexSelectedElement] = useState(0);
    const [isAdd, setIsAdd] = useState(true);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [redactProcessEmployeeQuery] = usePostProcessesRedactProcessEmployeeMutation();
    const {data: processRealTimeEmployees, isError: isRealTimeEmployeeError} = useGetProcessesSetEmployeeInProcessAndGetEmployeesInProcessQuery({processId: processId}, {pollingInterval: 3000});

    const onIncrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        await redactProcessEmployeeQuery({redactProcessEmployeeModel:{processBillProductId: inspectionProcessModel?.products[index].productProcessId!, isIncrement: true}});
        refetch();
    }

    const onDecrementPress = async (index:number) => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }
        
        await redactProcessEmployeeQuery({redactProcessEmployeeModel:{processBillProductId: inspectionProcessModel?.products[index].productProcessId!, isIncrement: false}});
        refetch();
    }

    const [completeProcess] = usePostProcessesInspectionCompleteMutation();
    const onSubmit = async () => {
        try {
            await completeProcess({inspectionCompleteModel: {processId: processId}}).unwrap();
            navigate(LinkPagesEnum.Processes);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }
    
    const onSomeIncrementPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }
        
        await redactProcessEmployeeQuery({redactProcessEmployeeModel:{processBillProductId: inspectionProcessModel?.products[indexSelectedElement].productProcessId!, isIncrement: true, quantity: quantityValue}});
        refetch();
        
        setQuantity(inspectionProcessModel?.products[indexSelectedElement].acceptanceQuantity! + quantityValue);
    }

    const onSetPress = async () => {
        if (!inspectionProcessModel?.products)
        {
            return;
        }

        const selectedProduct = inspectionProcessModel?.products[indexSelectedElement];

        if (quantityValue > selectedProduct.acceptanceQuantity!)
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel:{processBillProductId: selectedProduct.productProcessId!, isIncrement: true, quantity: quantityValue - selectedProduct.acceptanceQuantity!}});
        }
        else 
        {
            await redactProcessEmployeeQuery({redactProcessEmployeeModel:{processBillProductId: selectedProduct.productProcessId!, isIncrement: false, quantity: selectedProduct.acceptanceQuantity! - quantityValue}});
        }
        refetch();
        
        setQuantity(quantityValue);
    }
    
    const isFullBill = () => {
        if (inspectionProcessModel?.products?.find(pr => pr.quantity != pr.acceptanceQuantity) != null) {
            return false;
        }
        return true;
    }

    if (inspectionProcessModel == null) {
        return <div></div>;
    }

    return <div className={styles.wrapper}>
         <div className={styles.employeesContainer}>
            <div className={styles.employeesTitle}>
                Кто ещё в этом процессе:
            </div>
            {!isRealTimeEmployeeError ? 
            <div>
                {
                    processRealTimeEmployees?.map((el, idx) => <span className={styles.employee} key={el.employeeId}><span className={styles.number}>{idx + 1}. </span>{el.name} ({el.email})</span>)
                }
            </div>
            : <div>
                Ошибка
                </div>}

        </div>
        <div className={styles.percentBar} style={{backgroundColor: getPercentColor(inspectionProcessModel!.percent!)}}>
            {inspectionProcessModel?.percent}%
        </div>
        <div className={styles.topButttonsContainer}>
            <Button
                classNames={styles.toMain}
                text="На главную"
                onClick={()=> navigate("/")}
            />
        </div>
            <div className={styles.container}>
                <div className={styles.productsContainer}>
                {
                    inspectionProcessModel?.products?.map((el,index) => <ShipProductItem
                        onDecrement={() => onDecrementPress(index)} 
                        onIncrement={() => onIncrementPress(index)} 
                        name={el.name!} 
                        imgSrc={el.images != null ? el.images[0] : boxImg }
                        article={el.article!} 
                        factoryBarcode={el.factoryBarcode!} 
                        barcode={el.barcode!} 
                        awaitQuantity={el.quantity!} 
                        quantity={el.acceptanceQuantity!}
                        onAdd={() => {
                            setAwaitQuantity(el.quantity!);
                            setQuantity(el.acceptanceQuantity!);
                            setIsModalOpened(true);
                            setIndexSelectedElement(index);
                            setIsAdd(true);
                        }}
                        onSet={() => {
                            setAwaitQuantity(el.quantity!);
                            setQuantity(el.acceptanceQuantity!);
                            setIsModalOpened(true);
                            setIndexSelectedElement(index);
                            setIsAdd(false);
                        }}
                        />)
                }
                </div>
                <div className={styles.bottomButtonsContainer}>
                    <Button 
                        onClick={() => {
                            if (isFullBill()) {
                                onSubmit();
                            }
                            else {
                                setIsModalForEndInspectionOpen(true);
                            }
                        }} 
                        text="Завершить отгрузку"
                        />
                </div>
            </div>
        <Modal
            close={() => setIsModalOpened(false)} 
            isOpen={isModalOpened}
            isCloseCloseIcon
            >
                <>
                <Button 
                    text="Закрыть"
                    onClick={() => {
                        setAwaitQuantity(0);
                        setQuantity(0);
                        setIsModalOpened(false);
                    }}
                />
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td>Ожид. кол-во</td>
                                <td>Скан. кол-во</td>
                                <td>Ост. кол-во</td>
                            </tr>
                            <tr>
                                <td>{awaitQuantity}</td>
                                <td>{quantity ?? 0}</td>
                                <td>{(awaitQuantity - (quantity ?? 0))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <input 
                    value={quantityValue}
                    onChange={(e) => {
                        let val = Number(e.target.value);
                        
                        if (val) {
                            if (val > (awaitQuantity - (quantity ?? 0))) {
                                setQuantityValue((awaitQuantity - (quantity ?? 0)));
                            } 
                            else {
                                setQuantityValue(val)
                            }
                        } else {
                            setQuantityValue(0)
                        }
                    }}
                    className={styles.inputQuantityValue}
                />
                <Button 
                    text={isAdd ? "Добавить" : "Установить"}
                    onClick={() => {
                        isAdd 
                            ? onSomeIncrementPress()
                            : onSetPress();
                        setQuantityValue(0);
                    }}
                />
                </>
            </Modal>
        
            <Modal
                inlineStyles={{maxWidth: "90%"}}
                isCloseCloseIcon
                close={() => setIsModalForEndInspectionOpen(false)}
                isOpen={isModalForEndInspectionOpen}
                >
                    <Button 
                        text="Закрыть"
                        onClick={() => setIsModalForEndInspectionOpen(false)}
                    />
                    <div className={styles.modalText}>
                        Закрыть накладную даже, если есть расхождения: 
                    </div>
                    <Button 
                        text="Да"
                        onClick={onSubmit}
                    />
            </Modal>
    </div>
};

export {
    ShipProcessPage
};