import { baseApi } from "./";

export const enhanceEndpointsBaseApi = baseApi.enhanceEndpoints({
	endpoints: {
		// postProducts: {
		// 	query: (arg) => {
		// 		const formData = new FormData();

		// 		formData.append("ServiceProductPrice", arg.body.ServiceProductPrice?.toString() ?? "0");
		// 		formData.append("OrganizationName", arg.body.OrganizationName ?? "");
		// 		formData.append("Name", arg.body.Name ?? "");
		// 		formData.append("FactoryBarcode", arg.body.FactoryBarcode ?? "");
		// 		formData.append("Article", arg.body.Article ?? "");
		// 		formData.append("Barcode", arg.body.Barcode ?? "");
		// 		formData.append("Color", arg.body.Color ?? "");
		// 		formData.append("OwnerId", arg.body.OwnerId!.toString());
		// 		formData.append("File", arg.body.File ?? "");

		// 		return {
		// 			url: "/products",
		// 			method: "POST",
		// 			body: formData,
		// 			formData: true,
		// 		};
		// 	}
		// },
		// postProductsRedact: {
		// 	query: (arg) => {
		// 		const formData = new FormData();

		// 		formData.append("ServiceProductPrice", arg.body.ServiceProductPrice?.toString() ?? "0");
		// 		formData.append("OrganizationName", arg.body.OrganizationName ?? "");
		// 		formData.append("Name", arg.body.Name ?? "");
		// 		formData.append("FactoryBarcode", arg.body.FactoryBarcode ?? "");
		// 		formData.append("Article", arg.body.Article ?? "");
		// 		formData.append("Barcode", arg.body.Barcode ?? "");
		// 		formData.append("Color", arg.body.Color ?? "");
		// 		formData.append("File", arg.body.File ?? "");
		// 		formData.append("Id", arg.body.Id!.toString());

		// 		return {
		// 			url: "/products/redact",
		// 			method: "POST",
		// 			body: formData,
		// 			formData: true,
		// 		};
		// 	}
		// },
		// postProductsExcel: {
		// 	query: (arg) => {
		// 		const formData = new FormData();

		// 		formData.append("File", arg.body.File ?? "");

		// 		return {
		// 			url: "/products/excel",
		// 			method: "POST",
		// 			body: formData,
		// 			formData: true,
		// 		};
		// 	}
		// },
		postProductsImage: {
			query: (arg) => {
				const formData = new FormData();

				formData.append("File", arg.body.File ?? "");
				formData.append("ProductId", arg.body.ProductId!.toString() ?? "0");

				return {
					url: "/products/image",
					method: "POST",
					body: formData,
					formData: true,
				};
			}
		},
		putUsersProfileImage: {
			query: (arg) => {
				const formData = new FormData();

				formData.append("Image", arg.body.image ?? "");

				return {
					url: "/users/profileImage",
					method: "PUT",
					body: formData,
					formData: true,
				};
			}
		},
		// getDocumentsExcelBills: {
		// 	query: (arg) => {
		// 		return {
		// 			url: "/documents/excel/bills",
		// 			method: "POST",
		// 			body: arg,
		// 			responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
		// 			cache: "no-cache",
		// 		};
		// 	}
		// }
	}
});
